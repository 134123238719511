export default {
    list: {
        title: "Requests Offers",
        class: "min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center",
        name: "affiliates",
        awayLimit: true,
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: false,
                method: true,
                link: true,
                name: "Actions",
                buttons: [
                   
                ],
            },
        },
        columns: [
            
            {
                name: "ID",
                elements: [
                    {
                        items: [
                            {
                                class: "",
                                name: "id",
                                type: "text",
                            },
                        ],
                    },
                ],
            },
            {
                name: "Seller",
                elements: [{
                    items: [{
                        class: "",
                        name: "seller",
                        elements: [{
                            items: [{
                                name: "fullName",
                                class: "font-light text-gray-700",
                            }]
                        }]

                    }, ],
                }, ]
            },
            {
                name: "Offre",
                elements: [
                    {
                        items: [
                            {
                                class: "",
                                name: "name",
                                type: "text",
                            },
                        ],
                    },
                ],
            },
            
            
            {
                name: "Date demande",
                class: "",
                elements: [
                    {
                        items: [
                            {
                                class: "",
                                date: true,
                                name: "createdAt",
                                type: "text",
                            },
                        ],
                    },
                ],
            },
    
            {
                name: "status",
                class: "",
                elements: [
                    {
                        items: [
                            {
                                class: "select-status",
                                options: ['pending','approved','rejected'],
                                name: "status",
                                selects:true,
                                table: "affiliates",
                                type: "text",
                            },
                        ],
                    },
                ],
            },
           
        ],
        actions: {
            name: "actions",
            width: "10px",
            class:
                "rounded-full flex justify-center items-center focus:outline-none w-8 h-8 mr-3 mt-1",
            elements: [
                {
                    icone: "remove_red_eye",
                    color: "danger",
                    role: true,
                    key: "read",
                    link: "/affiliates/offers",
                    tooltip: "View",
                },
                {
                    icone: "delete",
                    color: "danger",
                    role: true,
                    key: "delete",
                    tooltip: "Delete",
                },
            ],
        },
        filters: {
            name: "products",
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "ID Offer, ID Requests Offer, ...",
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    },
                },
                champs: [
                    {
                        label: "status",
                        key: "status",
                        value: null,
                        values: [],
                        class:
                            "py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white",
                        type: "select",
                    },
                    {
                        label: "seller",
                        key: "seller",
                        name: "fullName",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "Warehouses",
                        key: "warehouse",
                        name: "name",
                        value: null,
                        values: [],
                        class:
                            "py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white",
                        type: "select",
                    },
                    {
                        label: "Catégories",
                        key: "category",
                        value: null,
                        values: [],
                        class:
                            "py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white",
                        type: "select",
                    },
                    {
                        label: "Date Demande",
                        key: "Range_date",
                        value: null,
                        placeholder: "Date Range ...",
                        type: "date_range",
                        class: "py-2 text-xs px-2 outline-none block h-full w-full"
                    },
                ],
            },
        },
    },
};
