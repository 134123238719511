<template>
  <div class="p-1">
    <div-table
      :config="configList"
      :data="charges"
      :idWharhouse="idWharhouse"
      :currentUser="currentUser"
      :limit="limit"
      @reset="reset"
      @refresh="refresh"
      @filtrer="filtrer"
      @search="search"
      :loading="loading"
      :paginate="paginate"
      @paginatation="paginatation"
      @Changelimit="Changelimit"
    ></div-table>
  </div>
</template>

<script>
import config from "./config";
export default {
  props: {
        currentUser: { type: Object},
        idWharhouse: { type: Object}
  },
  data() {
    return {
      configList: config.list,
      loading: false,
      formData: {},
      charges: [],
      sellers: [],
      tabId:[],
      limit: 10,
      filters:{},
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
    };
  },
  computed: {
    warhouseSelected(){
            return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
    },
  }, 
  watch: { 
      'warhouseSelected': async function(oldVal, newVal) {

      },
      'idWharhouse': async function(oldVal, newVal) {
          this.idWharhouse=oldVal;  
          await this.reset();
         // if (this.currentUser.type != "Seller") {  await this.getSellers();this.setDataSelect("seller", this.sellers, null);}
      }
},
  async mounted() {

    if (this.currentUser.type === "Seller") {
        let pos = this.configList.columns.map(function (e) {return e.name;}).indexOf('Seller');
        if(pos!=-1)  this.configList.columns.splice(pos, 1);  
        // let pos1 = this.configList.filters.elements.champs.map(function (e) {return e.key;}).indexOf('status');
        // if(pos1!=-1)  this.configList.filters.elements.champs.splice(pos1, 1);  
        this.filters.status="approved";
    }
    if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
    else this.$f.objectToQueryString({limit:this.limit,...(this.currentUser.type === "Seller"?{status:"approved"}:{})})

    if(this.filters.name) await this.search(this.filters.name);
    else await this.getAvoirs(this.filters);
    if (this.currentUser.type !== "Seller") await this.getSellers();
    this.setDataSelect("seller", this.sellers, null);
    this.setDataSelect("status", ['pending','approved','rejected'], null);
    this.setDataSelect("category", this.$f.defaultCategories(), null);  
    this.setDataSelect("warehouse",this.currentUser.countries, null);
  },
  methods: {
    async getwharhouseId(){
       await this.$store.dispatch('wharhouse/getwharhouseId',{country: this.warhouseSelected,}).then((res) => {this.idWharhouse=res;})
    },
     setDataSelect(model, data, splice) {
      for (var i = 0; i < this.configList.filters.elements.champs.length; i++) {
        if (
          this.configList.filters.elements.champs[i].type === "select" &&
          this.configList.filters.elements.champs[i].key === model
        ) {
          if (!splice) {
            this.configList.filters.elements.champs[i].values = data;
          } else {
            this.configList.filters.elements.champs.splice(i, 1);
          }
        }
      }
    },
    async getSellers() {
      const filter = {
         type: "Seller",
         countries:this.idWharhouse.country,
         limit:10,
      };
       if (this.currentUser.type != "Seller") {
          const res = await this.$server.search("users", filter);
          if (res.content.results) this.sellers = res.content.results;
          else this.sellers = [];
       }
    },
    async Changelimit(val){
       if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
       this.filters['limit']=val;
       this.filters['page']=1;
       this.paginate.currentpage=1;
       this.$f.objectToQueryString(this.filters)
       if(this.filters.name) await this.search(this.filters.name);
       else this.getAvoirs(this.filters);
    },
    async filtrer(data) {
       if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
       delete  this.filters.page;
       delete  this.filters.name;

       console.log('filter search________',data)
       this.paginate.currentpage=1;
       if(!this.filters['limit']) this.filters['limit']=this.limit;
       if(data.seller)  {this.filters['seller._id']=data.seller._id;}else delete this.filters['seller._id'];
       if(data.status)  {this.filters.status=data.status;}else delete this.filters.status;
       if(data.Range_date && data.Range_date[0]){this.filters.Range_date=data.Range_date.map( el => this.$moment(el).format('yyyy-MM-DD'));}else delete this.filters.Range_date;
       if(data.warehouse)  {this.filters['productObject.countries']=data.warehouse.code;}else delete this.filters['productObject.countries'];
       if(data.category)  {this.filters['productObject.category']=data.category;}else delete this.filters['productObject.category'];
       this.$f.objectToQueryString(this.filters)
      await this.getAvoirs(this.filters);
    },
    /**********************************User and Status******************************************************/

   
    async reset() {
        let lmitt=this.limit;
        if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
        if(this.filters['limit']) lmitt=this.filters['limit'];
        this.filters['page']=1;
        this.$f.objectToQueryString({limit:lmitt})
        await this.getAvoirs( {limit:lmitt});
    },
    async refresh() {
        if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
        this.$f.objectToQueryString(this.filters)
        if(this.filters.name) await this.search(this.filters.name);
        else await this.getAvoirs(this.filters);
       if(this.filters['page'] && this.filters['page']>1 && this.charges.length==0){
          this.filters['page']= --this.filters['page'];
           this.$f.objectToQueryString(this.filters)
          await this.getAvoirs(this.filters);
       }
    },
    async getAvoirs(filters) {
      this.loading = true;
      const res = await this.$server.search("affiliates", filters);
      this.loading = false;
      if (res && res.content.results) {
        this.charges = res.content.results;
        this.charges.forEach(function (offre) {
          offre.oldStatus= offre.status;
        }.bind(this));

        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.charges = [];this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}
      }
    },
    async search(data) {
       this.paginate= {total: 0,currentpage: 1,lastpage: 1,per_page: 0,};
       let limitt=this.limit
       if(this.filters['limit']) limitt=this.filters['limit'];
       const search = {
        name:data.trim(),
        limit:parseInt(limitt),
      }

     this.$f.objectToQueryString(search)
     const  res = await this.$server.find("affiliates", search);
       if (res.content.results) {
        this.charges = res.content.results; 
        this.charges.forEach(function (offre) {
          offre.oldStatus= offre.status;
        }.bind(this));
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.charges = [];this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}
      }
        
    },
    async remove(id) {
      this.$confirm("You want to delete this affiliate?").then(async (res) => {
        if (res) {
          const resp = await this.$server.delete("affiliates", { id: id });
          await this.refresh();
          alert("affiliate Deleted", "success");
        }
      });
    },
    /********************************************************************************************/
    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }
      if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
      this.filters['page']=page;
      this.$f.objectToQueryString(this.filters)

      this.getAvoirs(this.filters);
    },
  },
};
</script>

<style>
/* animation for vue transition tag */

</style>
